<template>
    <div>
        <page-header pageTitle="RESERVE.REALTORS_RESERVE" :haveSearch="true"
                     @searching="search($event)"></page-header>
        <div class="card" v-if="hastwoAllotments">
            <div class="card-header">
                <div class="card-title">
                    <label for="state">{{ t('NAV.ALLOTMENT') }}</label>
                </div>
            </div>
            <div class="card-body pt-0">
                <Select2 :settings="{ width: '50%', placeholder: 'Selecione um Loteamento' }" id="allotment"
                         name="allotment" v-model="allotment_id"
                         :options="allotments" @select="changeAllotment($event)"/>
            </div>
        </div>
        <div class="row gutters" v-if="items?.data && (items?.data?.length || Object.keys(items.data).length)">
            <div v-for="item  in items.data" :key="item.id" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 ">
                <figure class="user-card">
                    <figcaption>
                        <div :id="item.id"></div>
                    </figcaption>
                </figure>
            </div>
        </div>
        <div class="card" v-else>
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('RESERVE.MSG.NONE_RECORD_FIND') }}</h5>
                </div>
            </div>
        </div>
        <pagination-component v-if="items && items.data" :items="items" :to="'/corretores/reservas'"
                              @changePage="renderChart(filter, $event)"></pagination-component>
    </div>
</template>

<script>
import pageHeader from "../../components/layouts/pageHeader";
import {mapState} from "vuex";
import Reserves from "../../services/Reserves";
import * as ApexCharts from "apexcharts";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Select2 from 'vue3-select2-component';
import PaginationComponent from "@/components/layouts/PaginationComponent";

export default {
    name: "indexRealtorsReserves",
    components: {
        pageHeader,
        Select2,
        PaginationComponent
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    computed: {
        ...mapState({
            allotments: 'allotments',
            hastwoAllotments: 'hastwoAllotments',
        }),
    },
    beforeRouteLeave(to, from, next) {
        if (!this.chart) {
            return next();
        } else {
            this.chart.forEach(item => item.destroy());
            this.chart = [];
            return next();
        }
    },
    data() {
        return {
            allotment_id: null,
            items: null,
            filters: null,
            chart: [],
        }
    },
    mounted() {
        if (localStorage.getItem('reserveAllotmentId')) {
            this.allotment_id = localStorage.getItem('reserveAllotmentId');
        }
        this.renderChart();
    },
    methods: {
        async index(filter, page) {
            if (!this.allotment_id) {
                try {
                    this.allotment_id = await this.allotments[0].id;
                } catch (e) {
                    this.$router.push({name: 'IndexReserve'});
                    return
                }
            }
            await Reserves.reservesByRealtor(this.allotment_id, filter, page).then(resp => {
                this.items = resp.data;
            });
        },
        changeAllotment(allotment) {
            this.allotment_id = allotment.id;
            if (this.chart.length) {
                this.chart.forEach(chart => {
                    chart.destroy();
                })
            }
            this.renderChart();
        },
        async search(filter) {
            this.$store.commit('changeLoading', true);
            this.chart.forEach(item => item.destroy());
            this.chart = [];
            this.filter = {filtro: filter};
            await this.renderChart(this.filter, 1);
            this.$store.commit('changeLoading', false);
        },
        async renderChart(filter = null, page = 1) {
            this.$store.commit('changeLoading', true);
            await this.index(filter, page);
            let item = this.items.data;
            for (const key in item) {
                var options = {
                    series: [item[key].qtdReservesProgress, item[key].qtdReservesConfirmed, item[key].qtdReservesExpired, item[key].qtdReservesInterrupted],
                    chart: {
                        type: 'donut',
                        height: '190px',
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 10,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: `${item[key].user_name}${key}`,
                                    columnDelimiter: ',',
                                    headerCategory: 'category',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return new Date(timestamp).toDateString()
                                    }
                                },
                                svg: {
                                    filename: `${item[key].user_name}${key}`,
                                },
                                png: {
                                    filename: `${item[key].user_name}${key}`,
                                }
                            },
                            autoSelected: 'zoom'
                        },
                    },
                    title: {
                        text: item[key].user_name,
                        align: item[key].user_name?.length > 40 ? 'left' : 'center',
                        margin: 20,
                        style: {
                            fontSize: '13px',
                        },
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300,
                                height: 300,
                            },
                            legend: {
                                position: 'bottom',
                            }
                        }
                    }],
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                    },
                                    value: {
                                        show: true
                                    },
                                    total: {
                                        show: true,
                                        color: '#373d3f',
                                    }
                                }
                            }
                        }
                    },
                    legend: {
                        show: true,
                        fontSize: '14px',
                        horizontalAlign: 'left',
                        offsetY: 30,
                    },
                    labels: ['Em progresso', 'Confirmada', 'Expirada', 'Interrompida'],
                };

                let el = document.getElementById(item[key].id);
                if (el) {
                    let chart = new ApexCharts(el, options);
                    chart.render();
                    this.chart.push(chart);
                }

            }
            this.$store.commit('changeLoading', false);
        }
    }
}
</script>

<style scoped>
.apexcharts-legend {
    justify-content: start !important;
}
</style>